.Callback {
  text-align: center;
  margin: 100px 0;
}

.Callback__IdCard {
  color: #aaaaaa;
}

.Callback__Magnifier {
  position: relative;
  left: -0.73em;
  top: 0.1em;
  color: black;
}
