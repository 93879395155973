.TopMenu {
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 30px;

  border-bottom: 1px solid silver;
}

.TopMenu__ItemWrapper {
  text-align: center;
}

button.TopMenu__Item,
button.TopMenu__Item span {
  font-family: 'Raleway', sans-serif;
  font-size: 23px;
  text-transform: none;
}

button.TopMenu__Item--Current,
button.TopMenu__Item--Current span {
  font-size: 40px;
  text-decoration: initial;
  font-weight: 400;
  color: #555;
  cursor: default;
}
