.UserLoggedIn {
}

.UserLoggedIn__Avatar {
  margin: 0 7px 0 0;
}

button.UserLoggedIn__LogoutButton {
  margin: -40px 0 0 0;
}
