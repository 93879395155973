.BottomRibbon {
  margin: 30px 0 0 0;
  padding: 30px 0;

  border-top: 1px solid silver;
}

.BottomRibbon__Line {
  text-align: center;
}

.BottomRibbon__TechPiece {
  margin: 0 5px;
}
