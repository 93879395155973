html {
  font-size: 62.5%;

  display: block;
  font-size: 10px;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

body {
  font-family: 'Raleway', sans-serif;

  line-height: 1.6;
  font-size: 1.5em;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 20px;
  font-weight: 400;
}

h1 {
  font-size: 50px;
  line-height: 60px;
}

h2 {
  font-size: 42px;
  line-height: 52.5px;
}

h3 {
  font-size: 36px;
  line-height: 46.8px;
}

p, div, span, a {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: justify;
}

p {
  margin-bottom: 25px;
}

span {
  text-align: center;
}

a {
  color: rgb(30, 147, 219);
  cursor: pointer;
  -webkit-text-decoration-color: rgb(30, 147, 219);
          text-decoration-color: rgb(30, 147, 219);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
}

.centered {
  text-align: center;
}

.hidden {
  visibility: hidden;
}
